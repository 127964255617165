<template>
  <v-container fluid class="pa-0 register cart">
    <back-to-store :title="$t('cart.title')"></back-to-store>
    <v-container fluid>
      <v-row style="background: #fafafa">
        <v-col cols="12" lg="8" class="pa-16" height="100%">
          <h3 class="text-h5 black-grin pb-5 text-center text-lg-left"
          v-html="$t('cart.subtitle', { count : productsCart.items_count})">
          </h3>
          <hr class="hr-sub-menu pb-5" />

          <v-simple-table class="d-none d-lg-flex">
            <template v-slot:default>
              <thead class="josefin-light font-weight-light ">
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left p">
                    {{$t('cart.item')}}
                  </th>
                  <th class="text-center p">
                    {{$t('cart.price')}}
                  </th>
                  <th class="text-center p">
                     {{$t('cart.quantity')}}
                  </th>
                  <th class="text-center p">
                     {{$t('cart.total')}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, productIndex) in productsCart.items" :key="item.name">
                  <td>
                    <v-card
                      height="170"
                      width="170"
                      elevation="0"
                      class="rounded-0 d-flex align-center"
                      color="white"
                    >
                      <v-img
                        :src="item.product.base_image.medium_image_url"
                        max-width="70"
                        height="145"
                        class="ma-auto"
                      >
                      </v-img>
                    </v-card>
                  </td>
                  <td class=" josefin-light ">
                    <span class="copperplate-medium" v-html="item.product.short_description"></span>
                    <br />{{ item.name }}
                    <br />
                    <v-btn
                      class="btn_first delete_product mt-8"
                      elevation="0"
                      medium
                      @click="removeProduct(item.id)"
                      >{{$t('cart.btnCart')}}</v-btn
                    >
                  </td>
                  <td class="font-weight-regular text-center josefin-light">RD${{ Intl.NumberFormat().format(item.price) + '.00' }}</td>
                  <td>
                    <v-row justify="center">
                      <v-col cols="2" class="pa-0 ma-0 justify-end d-flex">
                        <v-btn
                          class="btn_number"
                          type="button"
                          :disabled="item.quantity === 1"
                          @click="updateQuantity(-1, productIndex)"
                        >
                          <span class="copperplate gold"> -</span>
                        </v-btn></v-col
                      >
                      <v-col cols="4" class="pa-0 ma-0">
                        <v-text-field
                          counter
                          v-model="item.quantity"
                          class="input_number ma-0 pa-0 text-center"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" class="pa-0 ma-0">
                        <v-btn
                          class="btn_number"
                          type="button"
                          @click="updateQuantity(1, productIndex)"
                        >
                          <span class="copperplate gold"> +</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                  <td class="font-weight-bold text-center josefin-light">RD${{ Intl.NumberFormat().format(item.total) + '.00' }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row
            class="d-lg-none py-3"
            v-for="(item, productIndex) in productsCart.items"
            :key="productIndex"
          >
            <v-col cols="12" class="justify-center d-flex">
              <v-card
                height="170"
                width="170"
                elevation="0"
                class="rounded-0 d-flex align-center"
                color="white"
              >
                <v-img
                  :src="item.product.base_image.medium_image_url"
                  max-width="70"
                  height="145"
                  class="ma-auto"
                >
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="12">
              <span class="copperplate text-center" v-html="item.product.short_description"></span>
              <v-row justify="center">
                <span class="josefin-light text-center">{{ item.name }}</span>
              </v-row>
              <v-row justify="center">
                <v-btn
                  class="btn_first delete_product mt-3"
                  elevation="0"
                  medium
                  @click="removeProduct(item.id)"
                  >Eliminar</v-btn
                >
              </v-row>
            </v-col>
            <v-col cols="12" class="mt-3">
              <v-row>
                <v-col><p class="josefin-light font-weight-bold ">Precio</p></v-col>
                <v-col class="justify-end d-flex">
                  <p class="josefin-light font-weight-bold">
                    RD${{ Intl.NumberFormat().format(item.price) + '.00' }}</p></v-col
                >
              </v-row>
              <v-row>
                <v-col><p class="josefin-light font-weight-bold ">Cantidad</p></v-col>
                <v-col
                  ><v-row justify="end" class="mr-3 mr-sm-0">
                    <v-col cols="2" sm="1" class="pa-0 ma-0 justify-end d-flex">
                      <v-btn
                        class="btn_number"
                        type="button"
                        :disabled="item.quantity === 1"
                        @click="updateQuantity(-1, productIndex)"
                      >
                        <span class="copperplate gold"> -</span>
                      </v-btn></v-col
                    >
                    <v-col cols="4" sm="2" class="pa-0 ma-0">
                      <v-text-field
                        counter
                        v-model="item.quantity"
                        class="input_number ma-0 pa-0 text-center"
                        hide-details
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="1" class="pa-0 ma-0">
                      <v-btn
                        class="btn_number"
                        type="button"
                        @click="updateQuantity(1, productIndex)"
                      >
                        <span class="copperplate gold"> +</span>
                      </v-btn>
                    </v-col></v-row
                  ></v-col
                >
              </v-row>
              <v-row>
                <v-col><p class="josefin-light font-weight-bold ">Total</p></v-col>
                <v-col class="justify-end d-flex">
                  <p class="josefin-light font-weight-bold">RD${{ Intl.NumberFormat().format(item.total) + '.00' }}</p></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4" style="background: rgba(29, 30, 28, 0.05)" class="pa-16">
          <h3 class="text-h5 black-grin pb-5">Resumen</h3>
          <hr class="hr-sub-menu pb-5" />
          <v-row>
            <v-col>
              <p class="font-weight-regular josefin-light">Sub-Total</p>
            </v-col>
            <v-col>
              <p class="font-weight-regular text-right josefin-light">
                RD${{ Intl.NumberFormat().format(productsCart.base_sub_total) + '.00' }}
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-n10">
            <v-col>
              <p class="font-weight-regular josefin-light">ITBIS</p>
            </v-col>
            <v-col>
              <p class="font-weight-regular text-right josefin-light">({{$t('cart.taxesIncluded')}})</p>
            </v-col>
          </v-row>
          <v-row class="mt-16">
            <v-col>
              <p class="font-weight-bold josefin-light">TOTAL</p>
            </v-col>
            <v-col>
              <p class="font-weight-bold text-right josefin-light">
                 RD${{ Intl.NumberFormat().format(productsCart.base_grand_total ) + '.00'}}
              </p>
            </v-col>
          </v-row>
        
          <v-row class="mt-16 ma-0">
            <router-link to="/payment-info" class="payment-button">
              <v-btn class="btn_second ma-0" elevation="0" medium>{{$t('cart.btnPay')}}</v-btn>
            </router-link>
          </v-row>
          <v-row class="mx-0 mt-5">
        
              <v-img src="@/assets/footerVisa.jpeg" max-width="80" contain class="mr-3"/>
                   <v-img src="@/assets/footerMastercard.png" max-width="100" contain />
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";
import backToStore from "@/components/home/back-to-store.vue";

export default {
  name: "cartList",
  components: {
    backToStore
  },
  async created() {
   await this.fetchCartDetails();
   
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    productsCart: '',
    quantity: null,
    debounceQuantity: null
  }),
  computed: {
    ...mapGetters("shoppingCart", ["getCart"]),
    ...mapGetters("customer", ["getUser"])
  },
  methods: {
    ...mapActions("shoppingCart", ["removeProductInCart", "updateProductInCart", "fetchCartDetails"]),
    removeProduct(id) {
      this.removeProductInCart(id).then(() => this.$router.go());
       if (!this.getCart) {
     
      
    } 
    },
    updateQuantity(value, productIndex) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          container: "contact-message-modal",
          confirmButton:
            "btn-success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default",
          title: "h6"
        },
        buttonsStyling: false
      });
      const product = this.productsCart.items[productIndex];
      product.quantity += value;
      if (this.debounceQuantity) {
        this.debounceQuantity.clear();
      }
      // this.debounceQuantity = debounce(
      //   () => this.updateProductInCart({ cartItemId: product.id, qty: product.quantity }),
      //   1000
      // );
      this.debounceQuantity = debounce(async () => {
        try {
          await this.updateProductInCart({ cartItemId: product.id, qty: product.quantity });
        } catch (err) {
          swalWithBootstrapButtons.fire({
            title: this.$t('alerts.soldOut'),
            text: this.$t('alerts.soldOutMessage'),
            confirmButtonText: this.$t('alerts.btnConfirm'),
            icon: "error"
          });
          this.fetchCartDetails();
          throw err;
        }
      }, 1000);
      this.debounceQuantity();
    },
  },
  watch: {
    getCart(val) {
      if (val) {
        this.productsCart = val;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.theme--light.v-data-table,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
thead {
  th {
    font-weight: 400;
    color: black !important;
    border: none !important;
  }
}
tbody {
  td {
    border: none !important;
    padding-bottom: 20px !important;
    font-size: 16px !important;
  }
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0 !important;
}

.payment-button {
  min-width: 100%;
}
.btn_second {
  width: 100%;
}
.v-text-field__slot {
  border: 2px solid #000;
  text-align: center;
  width: 60px !important;
  height: 30px;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.btn_number {
  width: 20px !important;
  min-width: 20px !important;
  background: rgba(0, 0, 0, 0.8) !important;
  border-radius: 0;
}
.input_number {
  border: 2px solid rgba(0, 0, 0, 0.8);
}
.input_number:focus {
  outline: none !important;
}
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(0 0 0 / 80%) !important;
}
</style>
